export default function ContactPage(params) {
    return (
        <div>
            <div className="Constructing">I'm Still Working on This Page, Please Come Back Later. ^v^</div>
            <div className="listContact">
                <li>Email</li>
                <li>Phone</li>
                <li>LinkedIn</li>
                <li>GitHub</li>
            </div>
        </div>
    )
};
